$cor1   : #038c3e; //verde escuro
$cor2   : #0df205; //verde claro
$cor3   : #261a1a; //marrom escuro
$cor4   : #0d0d0d; //preto
$cor5   : #f2f2f2; //cinza claro
$corBd  : #bdbdbd;
$corAA  : #aaa;
$cor77  : #777;
$corDc  : #dcdcdc;
$wht    : #FFF;
$blk12  : rgba(0, 0, 0, 0.12);
$blk25  : rgba(0, 0, 0, 0.25);
$blk54  : rgba(0, 0, 0, 0.54);
$blk90  : rgba(13, 13, 13, 0.90);
$grn54  : rgba(0, 90, 0, 0.54);

$corFF00: rgba(255,255,255,0);
$corFF10: rgba(255,255,255,0.1);
$corFF25: rgba(250,250,250,0.25);
$corFF50: rgba(250,250,250,0.5);
$corFF75: rgba(250,250,250,0.75);
$corFF80: rgba(250,250,250,0.8);
$cor0010: rgba(0,0,0,0.1);
$cor0020: rgba(0,0,0,0.2);
$cor0050: rgba(0,0,0,0.5);
$gray10: rgba(236,237,242,0.1);
$gray50: rgba(236,237,242,0.5);
$bgBtnsGallery  : $corFF50;
$green25: rgba(0,100,0,0.25);
$green75: rgba(13,242,5,0.75);

$corFb  : #3b5998;
$corTt  : #00acee;
$corWa  : #128c7e;
$corMl  : #d50000;
$corPh  : #ff3d00;

$bgPrice: #eceff1;


@mixin transitionTransform {
    -webkit-transition: -webkit-transform .2s ease-in-out;
    -ms-transition: -ms-transform .2s ease-in-out;
    transition: transform .2s ease-in-out; 
}

@mixin searchOver {
    font-weight: 500;
    cursor: pointer;
    color: $cor1;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

#bomdapeste, body{
    display: flex;
    max-width: 100%; 
    min-height: 100%;
    font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;
    flex-direction: column; 
    .flexACenter{
        display: flex;
        align-items: center;
    }
    .gallery{
        .carousel{
            min-height: 200px;
        }
    }
    .pac-container{
        &.pac-logo{
            z-index: 9999;
        }
    }
    a{
        text-decoration: none;
        color: inherit;
    }
    .btn{
        &-success{
            color: $wht;
            background-color: $cor1 !important;
            border: 1px solid $cor1;
            min-width: 90px;
        }
        &-error{
            color: $wht;
            background-color: $corMl !important;
            border: 1px solid $corMl;
            min-width: 90px;
        }
        &-green{
            color: $cor1;
            border: 1px solid $cor1;
            min-width: 90px;
        }
    }
    .w-100{
        width: 100% !important;
    }
    .d-none{
        display: none!important;
        color: $cor5;
    }
    #Compartilhar-actions{
        margin-left: -16px;
    }

    .layout{
        margin-top: 64px;
        background-image: url(/imgs/bg_template.jpg);
        background-attachment: fixed;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: auto!important;
        width: 100%;
        min-height: 320px;
        padding-top: 50px;
        color: $cor4;
        //text-shadow: 0 0 3px $blk54;
        h2{
            z-index: 1;
            font-size: 22px;
            position: relative;
            margin: 0 1em 2em;
            padding: 0.8em;
            font-weight: 500;
            border-radius: 10px;
            background-color: $green75;
        }
        span{
            z-index: 1;
            margin-bottom: 3em;
            padding: 16px 32px;
            position: relative;
            font-weight: 800;
            background-color: $corFF75;
            border: 2px solid $cor4;
        }
        &:hover{
            span{
                border: 2px solid transparent;
                background-color: $cor1;
                color: $cor5;
            }
        }
    }
    .integrador{
        min-height: 320px;
        position: relative;
        color: $cor5;
        padding: 40px 0;
        &::before{
            content: " ";
            background-color: $blk90;
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            z-index: 1;
        }
        background: {
            size: 50%;
            position: bottom center;
            attachment: fixed;
            image: url(/imgs/sites.jpg);
        }
        h2{
            z-index: 1;
            font-size: 28px;
            position: relative;
        }
        p{
            z-index: 1;
            display: block;
            width: 90%;
            border-radius: 50px 0;
            margin: 16px auto 8px auto;
            position: relative;
            font-size: 21px;
            padding: 8px;
            color: $cor2;
            text-transform: uppercase;
            background-color: $blk54;
        }
        span{
            z-index: 1;
            padding: 16px 32px;
            position: relative;
            border: 2px solid transparent;
        }
        &:hover{
            span{
                border: 2px solid $cor5;
            }
        }
    }

    //--------- movido de body externo -------
    &.gray{
        background-color: $cor5;
    }
    .MuiSlider-root{
        color: $cor1;
        .MuiSlider-thumb{
            width: 20px;
            height: 20px;
            margin-top: -9px;
        }
    }
    .MuiChip-outlinedSecondary{
        color:$cor1;
        border: 1px solid $cor1;
    }
    .MuiChip-outlinedPrimary{
        color:$corMl;
        border: 1px solid $corMl;
    }
    .MuiChip-clickable.MuiChip-outlinedSecondary:hover, 
    .MuiChip-clickable.MuiChip-outlinedSecondary:focus, 
    .MuiChip-deletable.MuiChip-outlinedSecondary:focus{
        background-color: transparent;
    }
    .MuiChip-deleteIconOutlinedColorSecondary{
        color:$cor1;
    }
    .MuiChip-deleteIconOutlinedColorPrimary{
        color:$corMl;
    }
    .MuiCheckbox-colorSecondary.Mui-checked{
        color:$cor1;
    }
    .MuiTooltip-popper div{
        background-color: $cor1;
        font-weight: 400;
    }
    .alert{
        &-title{
            padding-bottom: 0;
            h2{
                line-height: 1.2em;
            }
            &-center{
                text-align: center;
            }
        }

        &-dialog-mb{
            border-bottom: 1px solid  $cor5;
            padding: 0.8em 1.3em;
            margin-bottom: 1.3em;
        }
        &-success{
            color:$cor1;
        }
        &-error{
            color:$corMl;
        }
    }
    .extra-small{
        font-size: 13px;
        color: $blk54;
    }
    //------------------ fim body--------------------------

    .app{
        //-----------movido de .app externo-------------
        
        &-model{
            &-select{
                width: 90%;
                margin: 2em auto 0;
                
            }
        }

        &-font{
            &-small{
                font-size: 11px;
            }
        }

        &-central{
            &-head{
                background-color: $wht;
                padding: 16px;
                display: flex;
                justify-content: space-between;
                
                background-color: $bgPrice;
                background-image: url("/imgs/dark-circles50.png");
                h6{
                    @include ellipsis
                }
                small{
                    font-size: 0.7em;
                    color: $corAA;
                }
                &-search{
                    width: calc(100% -  50px);
                    input{
                        text-indent: 1em;
                    }
                }
                &-info{
                    display: flex;
                    justify-content: space-between;
                }
                &-new{
                    color: $cor1;
                }
                &-total{
                    //width: 100%;
                    //display: block;
                    padding: 5px 16px;
                    text-align: right;
                }
            }
        }

        &-loading{
            z-index: 999;
            color: $wht;
        }

        &-user{
            &-nome{
                @media(max-width:375px) {
                    max-width: 110px;
                }
                @media(max-width:320px) {
                    max-width: 60px;
                }
            }
        }

        &-bg{
            &-white{
                background-color: $wht;
            }
            &-imgs{
                background-size: cover;
                background-position: center;
            }
        }

        &-content{
            min-height: calc(100% - 56px);
            
            &-items{
                padding-bottom: 64px;
                justify-content: center;
            }
        }

        &-form{
            .Mui-focused{
                color: $cor1!important;
                box-shadow: none;
                fieldset{ border-color:  $cor1!important; }
            }
            .MuiInput-underline{
                &::after{
                    border-bottom: solid 2px $cor1;
                }
                &.Mui-error::after{ 
                    border-bottom: solid 2px $corMl;
                }
            }
            .MuiSwitch-colorSecondary.Mui-checked{
                color: $cor1;
                &  + .MuiSwitch-track{
                    background-color: $cor1;
                }
            }
            .app-expanded .MuiIconButton-label{
                color: $wht;
            }
            .red{
                .MuiSwitch-colorSecondary.Mui-checked{
                    color: $corMl;
                    &  + .MuiSwitch-track{
                        background-color: $corMl;
                    }
                }
            }
            .orange{
                .MuiSwitch-colorSecondary.Mui-checked{
                    color: orange;
                    &  + .MuiSwitch-track{
                        background-color: orange;
                    }
                }
            }
            &-login{
                margin: auto;
                max-width: 400px;
                legend{
                    width: 0!important;
                }
                .MuiInputLabel-filled.MuiInputLabel-shrink{
                    transform: translate(10px, 7px) scale(0.65);
                }
            }
            &-input{
                background-color: $corFF80;
                border-radius: 5px;
            }
            &-send{
                background: $cor1;
                padding: 1em 0;
                color: $wht;
            }
            &-ins-cancel{
                margin: 1em 0px 3em 5%;
                width: 90%;
                background: $corMl;
            }

            .icon{
                &-info{
                    color: $cor0050;
                    cursor: pointer;
                    &:hover{
                        color: $cor1;
                    }
                }
            }
        }

        &-card{
            &-trans{
                background-color: $corFF50;
            }
        }

        &-dialog{
            color:black; 
            min-width: 258px;
            .MuiDialogContent-root{
                overflow-y: visible;
            }
            .MuiDialog-paper{
                min-width: 280px;
            }
            .alert-title{
                background-color: $corDc;
                padding-bottom: 1em;
            }
            &-msg{
                padding: 1em 0 0.5em;
                text-align: center;
            }
            &-btn{
                a{
                    color: $cor1;
                    width: 100%;
                    padding: 0.5em 1em;
                    border: 1px solid;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    small{
                        color: $cor3;
                    }
                    sub{
                        margin: 0 8px;
                    }
                    &:hover{
                        background-color: $cor5;
                    }
                }
                &-close{
                    border-top:1px solid $corDc;
                    padding-top:16px!important;
                    margin-top: 16px;
                }
            }
            &-footer{
                width: 90%;
                margin: auto;
                align-items: center;
                padding-bottom: 16px;
                justify-content: space-between;
            }
            &-ad{
                max-width:640px;
                background: #FFFFFFEE;
                padding: 2em;
                color: #000000;
                flex-flow: wrap;
                justify-content: space-between
            }
        }

        //------------ fim .app -----------------
        &-backdrop{
            z-index: 10;
            color: $cor1;
        }

        &-shop{
            &-tels{
                width: 290px;
                color: $cor1;
                margin: 16px 0 0;
                border: 1px solid;
            }
            
            &-dialog{
                background-color: $wht;
                padding: 16px 0;
                &.desk{
                    border-radius: 5px;
                    padding: 32px 0;
                    margin-top: -32px;
                    box-shadow: 0 4px 32px $blk12;
                }
                img{
                    @media(max-width:450px) {
                        width: 95%;
                    }
                }
                @media(max-width:800px) {
                    overflow: hidden;
                }
            }
        }

        &-search{
            position: relative;
            &-advanced{ 
                text-indent: 0.3em;
                &:hover{
                    @include searchOver;
                }
            }
            &-title{
                font-size: 23px;
                font-weight: 500;
                text-align: left;
                margin-bottom: 16px;
                display: none;
                &.desk{
                    display: flex;
                }
            }
            &-location{
                width: 100%;
                display: flex;
                margin-bottom: 8px;
                justify-content: flex-end;
                &.desk{
                    width: auto;
                }
                &:hover{
                    @include searchOver;
                }
            }
            &-dialog{
                background-color: $wht;
                padding: 16px;
                &.desk{
                    border-radius: 5px;
                    padding: 32px;
                    margin-top: -32px;
                    box-shadow: 0 4px 32px $blk12;
                }
            }
            &-box{
                display: flex;
                position: relative;
                border-radius: 5px;
                background-color: $cor5;
                min-height: 50px;
                width: 100%;
            }
            &-icon{
                height: 23px;
                padding: 0 4px 0 12px;
                pointer-events: none;
                align-items: center;
                justify-content: center
            }
            &-input{
                width: calc(100% - 40px - 90px);
                input{
                    padding: 8px;
                    width: 100%
                }
            }
            &-send{
                background-color: $cor1;
                height: 100%;
                box-shadow: none;
                border-radius: 0 5px 5px 0;
            }
            &-mic{
                padding: 40px;
                background-color: $cor1;
                color: $wht;
                display: flex;
                align-items: center;
            }
            &-insert{
                label{
                    color: $cor1
                }
                .MuiInputBase-root{
                    background-color: $wht;
                    &::before, &::after{
                       border-bottom: 0 solid $cor1;
                    }
                }
            }
        }

        &-facebook  { color: $corFb }
        &-twitter   { color: $corTt }
        &-whatsapp  { color: $corWa }
        &-mail      { color: $corMl }
        &-phone     { color: $corPh }

        &-banner{
            &-home{
                width: 100%;
                padding-top: 16.67%;
                background-size: cover;
            }
            &-shop{
                width: 100%;
                padding-top: 25%;
                background-size: auto, cover;
                background-repeat:repeat;
                background-position: center;
                @media(max-width: 640px) {
                    padding-top: 75%;
                }
            }
        }

        &-gray-color{
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
            &:hover{
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }
        }
        
        &-bg-gainsboro{
            background-color: $corDc;
        }

        &-go-top{
            color: $cor5;
            background-color: $cor1;
        }

        &-m{
         &-all{
            &-1{margin: 8px;}
            &-2{margin: 16px;}
            &-3{margin: 32px;}
            &-4{margin: 64px;}
            &-5{margin: 128px;}
         }
         &t{
            &-1{margin-top: 8px;}
            &-2{margin-top: 16px;}
            &-3{margin-top: 32px;}
            &-4{margin-top: 64px;}
            &-5{margin-top: 128px;}
         }
         &b{
            &-1{margin-bottom: 8px;}
            &-2{margin-bottom: 16px;}
            &-3{margin-bottom: 32px;}
            &-4{margin-bottom: 64px;}
            &-5{margin-bottom: 128px;}
         }
         &l{
            &-1{margin-left: 8px;}
            &-2{margin-left: 16px;}
            &-3{margin-left: 32px;}
            &-4{margin-left: 64px;}
            &-5{margin-left: 128px;}
         }
         &r{
            &-1{margin-right: 8px;}
            &-2{margin-right: 16px;}
            &-3{margin-right: 32px;}
            &-4{margin-right: 64px;}
            &-5{margin-right: 128px;}
         }
        }

        &-p{
         &-all{
            &-1{padding: 8px;}
            &-2{padding: 16px;}
            &-3{padding: 32px;}
            &-4{padding: 64px;}
            &-5{padding: 128px;}
         }
         &t{
            &-1{padding-top: 8px;}
            &-2{padding-top: 16px;}
            &-3{padding-top: 32px;}
            &-4{padding-top: 64px;}
            &-5{padding-top: 128px;}
         }
         &b{
            &-1{padding-bottom: 8px;}
            &-2{padding-bottom: 16px;}
            &-3{padding-bottom: 32px;}
            &-4{padding-bottom: 64px;}
            &-5{padding-bottom: 128px;}
         }
         &l{
            &-1{padding-left: 8px;}
            &-2{padding-left: 16px;}
            &-3{padding-left: 32px;}
            &-4{padding-left: 64px;}
            &-5{padding-left: 128px;}
         }
         &r{
            &-1{padding-right: 8px;}
            &-2{padding-right: 16px;}
            &-3{padding-right: 32px;}
            &-4{padding-right: 64px;}
            &-5{padding-right: 128px;}
         }
        }

        &-flex{
            display: flex;
            &-column{
                flex-direction: column;
            }
            &-row{
                flex-direction: row;
            }
        }

        &-align{
            &-center{
                align-items: center;
            }
        }

        &-justify{
            &-end{
                justify-content: flex-end;
            }
            &-start{
                justify-content: flex-start;
            }
            &-between{
                justify-content: space-between;
            }
            &-center{
                justify-content: center;
            }
        }

        &-ellipsis{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-title-icon-small{
            font-size: 1em;
            margin: 0 0.2em 0 1em;
        }

        //>>> FORMS <<<
        &-form{
            width: 100%;
            display: flex;
            justify-content: center;
            flex-flow: column;
            &-title{
                padding: 0 16px;
                text-align: center;
                margin-bottom: 16px;
            }
            &-field{
                width: 90%;
                margin: auto 5%;
                .active{
                    color: $cor5;
                    background-color: $cor1;
                }
            }
            .Mui-focused{
                color:green;
                box-shadow: none;
                fieldset{ border-color: green; }
            }
        }

        //>>> NAVBAR <<<
        &-nav{
            &-login{
                display: flex;
                margin-right: 24px;
                &-img{
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    background-color: $wht;
                    img{
                        width: 75%;
                        height: 75%;
                    }
                }
                span{
                    font-size: 14px;
                    color: $wht;
                }
            }
            &-top{
                background-color: $cor1;
            }
            &-logo{
                flex-grow: 1;
                margin-right: 16px;
                img{ height: 2.1em;}
            }
        }

        //>>> AD <<<
        &-black{
            color: $cor3;
            line-height: 0.9em;
        }

        &-ad{
            &-title{
                font-size: 15px;
                height: 3em;
                overflow: hidden;
                font-weight: 400;
                &-sub{
                    font-size: 13px;
                }
            }

            &-speedDial{
                box-shadow: none;
                width: 24px;
                height: 24px;
                font-size: 18px;
                background-color: transparent !important;
                button[aria-label=Compartilhar]{
                    color: $cor1;
                }
            }
        
            &-photo, &-actions{
                box-shadow: 0 0 1px $blk54;
            }

            &-actions{
                position: relative;
                padding-top: 0;
                &-more{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 1em;
                    padding-top:9px ;
                    svg{
                        color: $wht;
                    }
                    //position: absolute;
                }
            }
            &-price{
                background-color: $bgPrice;
                background-image: url("/imgs/dark-circles50.png")
            }
        }

        &-dial{
            position: relative;
            &-options{
                position: absolute;
                display: flex;
                top: 10px;
                left: 64px;
                z-index: 3;
            }
            &-icon{
                width: 35px;
                height: 35px;
                background-color: transparent;
                &.green{
                    svg{
                        color: $cor1;
                    }
                }
                &-over{
                    margin-right: 0.5em;;
                    background-color: $wht; 
                    box-shadow: 2px 2px 8px $blk25;
                }
            }
        }
        .price{
            text-align: right;
            display: block;
            color: $cor3;
            font-size: 2em;
            sup{
                font-size: 0.5em;
                margin-right: 0.3em;
            }
            s{
                font-size: 0.6em;
                margin-right: 1em;
                color: $blk54;
            }
        }

        &-half{
            width: 40%;
            display: inline-block;
            padding: 0.1em 0 0.4em 0.5em;
            &:nth-child(2n+1){
                width: 60%;
            }
            svg{
                color: $corBd;
            }
        }
        &-expanded{
            transform: rotate(0deg);
            transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            margin-left: auto;
            padding: 2px;
            height: 28px;
            //margin-right: 8px;
            box-shadow: 0 0 3px $corBd;
            background-color: $cor1;
            a{
                color: $wht;
            }
        }

        &-expandOpen {
            transform: rotate(180deg)
        }

        &-chip-plus{
            color: $cor5;
            background-color: $cor1;
        }
        // POLITICS
        &-politics{
            &-title{
                font-size: 2em;
                text-align: center;
                font-weight: 500;
                margin-top: 16px;
                @media(min-width:800px) {
                    margin-top: 0;
                }
            }
            .MuiChip-root{
                .MuiChip-avatar{
                    color: $wht;
                    background-color: $cor1;
                }
            }
        }

        //SERVICES
        &-serv{
            &-bg{
                margin: 0;
                background-color: $bgPrice;
                background-image: url(/imgs/dark-circles50.png);
                padding: {
                    bottom: 32px;
                    top:32px;
                }
            }
            &-steps{
                margin: 32px auto 16px auto;
                min-width: 220px;
                .MuiCard-root{
                    box-shadow: 0 0 0 transparent;
                }
                .MuiCardMedia-root{
                    width: 100%;
                    height: 150px;
                    margin-top: 8px;
                    background-size: 150px;
                }
                h2{
                    line-height: 1.1em;
                }
            }
            &-mais{
                position: relative;
                clear: both;
                button{
                    color: $cor5;
                    padding: 16px;
                    border-radius: 0;
                    background-color: $cor1;
                    &:disabled{
                        color: $corAA;
                        background-color: $cor77;
                    }
                }
            }
            &-loading{ 
                width: 30px!important;
                height: 30px!important;
                color: $cor4;
                position: absolute;
                left: calc(50% - 15px);
                top: calc(50% - 15px);
            }
        }

        // FOOTER
        &-footer{
            &-title{
                font-size: 18px;
                border-bottom: 1px solid $corBd;
                margin-bottom: 16px;
            }

            &-menu{
                color: $cor5;
                background-color: $cor4;
            }
            
            &-copyright{
                color: $cor5;
                text-align: center;
                background-color: $cor4;
                border-top: 1px solid $cor3;
                p{
                    font-size: 12px; 
                    font-weight: 100;
                    padding: 8px;
                }
            }
            &-cookies{
                left: 0;
                bottom: 0;
                z-index: 99;
                padding: 21px;
                font-size: 13px;
                position: fixed;
                color:  $cor1;
                text-align: center;
                background-color:$wht;
                justify-content: center;
                &-btn{
                    color: $wht;
                    border-radius: 0.3em;
                    padding: 8px 34px;
                    background-color: $cor1;
                }
            }
        }

        &-marks-plus{
            @include transitionTransform; 
            width: 39px;
            height: 39px;
            margin: 3px 5px;
            background-color: $corBd;
            &:hover{
                background-color: $cor1;
            }
            &-on{
                transform:rotate(45deg);
                -ms-transform:rotate(45deg);
                -webkit-transform:rotate(45deg); 
            }
        }
    }

    
    //.carousel{
    //    &__image{ background-position: center; }
        .gallery{
            position: relative;
            &-next, &-prev{
                top: 0;
                width: 30px;
                height: 100%;
                opacity: 0.6;
                font-size: 30px;
                position: absolute;
                &:focus{ outline: none }
                &:hover{ opacity: 1 }
                background: $bgBtnsGallery;
                background: linear-gradient(0deg,$corFF00 15%, $corFF10 25%, $corFF50 100%)
            }
            &-next{
                right: 0;
            }
            &-title{
                position: absolute;
                top: 0;
                height: 2.5em;
                width: 100%;
                background: transparent;
                background: linear-gradient(0deg, $gray10 0%, $gray50 50%, #ecedf2 99%)
            }
            &-price{
                bottom: 0;
                margin: 0;
                width: 100%;
                line-height: 1.1em;
                padding: 0.3em 0 0.8em 0;
                text-shadow: 1px 2px 3px $cor0050;
                font: {
                size: 21px;
                weight: 700;
                }
                text-align: center;
                position: absolute;
                color: $wht;
                background: $cor4;
                background: linear-gradient(180deg, $cor0010 0%, $cor0050 25%, $cor3 75%);
            }
            &-over{
                &::after{
                content: " ";
                width: 100%;
                height: 100%;
                background-color: transparent;
                position: absolute;
                top:0
                }
            }
        }
    //}
  
    //>>>>> DETALHES <<<<<<<
    .info{
        &-btns{
            //border:1px solid $corDc;
            //background-color: $cor5;
            button{
                color: $cor3;
                border: solid 1px $corDc;
                padding: 16px 8px;
                &:hover{
                    color: $cor1;
                }
            }
        }

        &.topUp{
            top:-2em;
            width:100%;
            position: relative;
            margin: {
                right: auto;
                left: auto;
            };
        }
        &-cover{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $corFF50;
            z-index: 2;
            border-radius: 1em;
        }
        
        &-modelo, &-contato{
            position: relative;
            background-color: $wht;
            padding: 2em 3.5em;
            border-radius: 1em;
            margin: 0 1em 2em 0;
            box-shadow: 0 0 16px $blk12;

            @media(max-width:960px) {
                top: 2em;
                padding: 1.5em;
                border-radius:0;
                margin: 0 0 2em 0;
                box-shadow: 0 0 0 $blk12;
            }
        }
    
        &-contato{
            padding: 2em;
            div{
                display: flex;
                img{
                    max-width: 400px;
                    margin: 0 auto;
                    position: relative;
                }
            }
        }
    
        &-title{
            color: $corAA;
            font-size: 30px;
            text-transform: uppercase;
            line-height: 0.8em;
            b{
                color: $cor4
            }
            small{
                color: $cor77;
                font-size: 14px;
                line-height: 0;
            }
        }
    
        &-items{
            line-height: 2em;
            label{
                margin: 0;
            }
            p{
                font-size: 16px;
                font-weight: 600;
            }
        }
    
        &-checked{
            display: inline-block;
            margin-bottom: 0.5em;
            &-row{
                flex-wrap: nowrap;
                line-height: 1.1em;
                margin-top: 8px;
            }
        }
        &-list{
            display: flex;
            flex-wrap: wrap;
        }
    
        &-preco{
            padding: 4px;
            color: $cor3;
            font-size: 28px;
            text-align: right;
            background-color: $bgPrice;
            background-image: url(/imgs/dark-circles50.png);
            b{
                margin-right: 0.5em;
                font-size: 38px;
                font-weight: 400;
            }
        }
        &-end{
            display: flex;
            justify-content: center;
            padding: 16px;
            background-color: $bgPrice;
            background-image: url(/imgs/dark-circles50.png);
            &-text{
                display: flex;
                align-items: center;
            }
        }
        &-icon{
            &-map{
                padding: 0 8px;
                svg{
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }


    .bg-cover{
        background-size: cover;
        &.green::after{
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: $green25;
        }
        &.black::after{
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: $blk54;
        }
    }
    //-------- FIM ---------
    
}
